import React, { FunctionComponent } from "react"
import Layout from "../layout/index"
import schema from "./index-schema.json"

import "./imprint.css"

const ImprintPage: FunctionComponent<Record<string, unknown>> = () => (
  <Layout
    lang="de"
    title="codaline - Datenschutzerklärung"
    description="Remote Softwareentwicklung, Beratung, Prototyping & Code Audits"
    microdata={JSON.stringify(schema)}
  >
    <div className="main-wrapper">
      <h1>Datenschutzerklärung</h1>
      <p>
        Wir freuen uns über Ihren Besuch auf unserer Website sowie über Ihr
        Interesse an unserem Unternehmen. Der Schutz ihrer privaten Daten ist
        uns ein sehr wichtiges Anliegen. Wenn Sie mittels email oder
        www-Formular Kontakt mit uns aufnehmen, sollten Sie wissen, dass wir
        Ihre persönlichen Daten (Namen, Adresse) die Sie uns übermitteln,
        elektronisch speichern, um sie für Kommunikationszwecke mit Ihnen zu
        verwenden. Wir werden Ihre Daten nicht an Dritte verkaufen oder
        weitergeben. Eine Übermittlung an Dritte erfolgt nur mit Ihrer
        Einwilligung, oder soweit sonst gesetzlich zulässig. Mit der Nutzung
        dieser Website und der Angabe ihrer persönlichen Daten per email oder
        www-Formular geben Sie Ihre Einwilligung zu der hier beschriebenen
        Erhebung, Verarbeitung und Nutzung Ihrer persönlichen Daten.
      </p>
      <h2>1. Datenschutz auf einen Blick</h2>

      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
        persönlich identifiziert werden können. Ausführliche Informationen zum
        Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>

      <h3>Datenerfassung auf unserer Website</h3>
      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
        Website entnehmen.
      </p>

      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
        Sie unsere Website betreten.
      </p>

      <h4>Wofür nutzen wir Ihre Daten?</h4>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>

      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
      <p>
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder
        Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum
        Thema Datenschutz können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <h3>Analyse-Tools und Tools von Drittanbietern</h3>
      <p>
        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit Cookies und mit
        sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt
        in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
        zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie
        durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
        Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
      </p>
      <p>
        Sie können dieser Analyse widersprechen. Über die
        Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung
        informieren.
      </p>

      <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>

      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Marcus Kirsch und Bengt Weiße
        <br />
        Schulstr. 2b
        <br />
        99628 Buttstädt OT Hardisleben
        <br />
        <a href="mailto:mail@codaline.io">mail@codaline.io</a>
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
        Ä.) entscheidet.
      </p>

      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt.
      </p>

      <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
      <p>
        Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
        Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
        Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
        seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren
        Kontaktdaten können folgendem Link entnommen werden:{" "}
        <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        .
      </p>

      <h3>Recht auf Datenübertragbarkeit</h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>

      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von “http://” auf “https://” wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>

      <h3>Auskunft, Sperrung, Löschung</h3>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden.
      </p>

      <h2>3. Datenerfassung auf unserer Website</h2>
      <h3>Cookies</h3>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert.
      </p>
      <p>
        Die meisten der von uns verwendeten Cookies sind so genannte
        “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
        gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
        diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
        oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
        (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von
        Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit
        andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens)
        gespeichert werden, werden diese in dieser Datenschutzerklärung
        gesondert behandelt.
      </p>

      <h3>Server-Log-Dateien</h3>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>
          IP-Adresse (vollständig anonymisiert; aus z.B. IP 11.22.33.44 wird
          127.0.0.1).
        </li>
      </ul>

      <p>
        Die anonymisierten Server-Log-Dateien werden nach 90 Tagen automatisch
        gelöscht. Eine Zusammenführung dieser Daten mit anderen Datenquellen
        wird nicht vorgenommen.
      </p>
      <p>
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der
        die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </p>

      <h3>Kontaktformular</h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
        somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu
        reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
        der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
        Widerruf unberührt.
      </p>
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>

      <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
      <p>
        Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit
        sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des
        Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
        Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
        zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
        Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
        (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
        erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
        ermöglichen oder abzurechnen.
      </p>
      <p>
        Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
        Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
        Aufbewahrungsfristen bleiben unberührt.
      </p>

      <h3>
        Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale
        Inhalte
      </h3>
      <p>
        Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im
        Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der
        Zahlungsabwicklung beauftragte Kreditinstitut.
      </p>
      <p>
        Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann,
        wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe
        Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken
        der Werbung, erfolgt nicht.
      </p>
      <p>
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
        die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </p>
    </div>
  </Layout>
)

export default ImprintPage
